import HttpUtil from '@/utils/HttpUtil';

export default {
  // 获取字典接口
  //param {'typeCode':''}
  dictList(params?: any) {
    let url = '/river-system/dict/query/list';
    return HttpUtil.post(url, params);
  },

  dictTree(params?: any) {
    let url = '/river-system/dict/query/tree';
    return HttpUtil.post(url, params);
  }
};
